import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`No one wants to read a story with a tragic ending. Especially if your favorite character dies. I think it’s better to read a story, where at the end the main character motivates the reader. Whether it’s motivation to think about life, or a motivation to do something useful with your life.`}</p>
    <p>{`But before we jump into the actual story, I shall introduce myself. My name is Kelly. I have already 28 years of experience in life. And for those 28 years, I have been living in New York City.`}</p>
    <p>{`Sounds pretty amazing, but once you live your entire life in a big city, it can get boring. Not that I am saying that I want to go live in a village, it’s just that life starts to feel like a routine.`}</p>
    <p>{`My day started by waking up at 7:00, preparing myself a cup of coffee, and 2 sandwiches. I took a shower and got ready for work.`}</p>
    <p>{`I should admit I worked in a pretty good company. The way the company presented itself might have been gorgeous, and outstanding, but you need years of work and ideas to make it outstanding.`}</p>
    <p>{`Hours of working and brainstorming. Even though I was one in a thousand employees, I felt like I was doing all the work for the entire company. Most of the high-ranked positions had men, well what would you honestly expect.`}</p>
    <p>{`I had a few good friends that worked with me. I was working in the financial department. I was responsible for the prices of the products that we were selling.`}</p>
    <p>{`I was paid enough, I was able to afford a small apartment in the outskirts of the city. I always had to wake up early to be on time for the bus, and stand in the crowded area for about 20 minutes.`}</p>
    <p>{`I was really excited that I was accepted to work in the company, right after I finished college. Each year, I was trying my best to become the financial director. I always aimed higher, but each year I felt like my hard work didn’t pay off.`}</p>
    <p>{`I knew that nothing would come to you right away. 6 years of working in the company and still no payoff.`}</p>
    <p>{`I watched my male friends get put into higher positions, and then there was me and my female coworkers that were still in the same positions.`}</p>
    <p>{`I worked day and night, trying to make everything perfect, not losing hope. I started to get sick, getting headaches, but I still tried. I wasn’t giving up, until my 6th year of working in the company passed.`}</p>
    <p>{`I was very disappointed. Again.`}</p>
    <p>{`My friends and I decided to go to a restaurant for lunch that day. I told them that I wanted to quit my job and start my own business. I offered them to join me after I told them my small idea of the business.`}</p>
    <p>{`My idea was to make a small beauty salon.`}</p>
    <p>{`They agreed because they were also suffering in the company. Plus, they had experience in, for example, manicuring, and hairstyling.`}</p>
    <p>{`The next day I quit my job, and so did my friends. We felt happy and free.`}</p>
    <p>{`We bought a small building, with all of our savings, and started to get to work. After 2 years of renovation and adjusting the beauty salon, we could finally open to the public.`}</p>
    <p>{`Now, I am the manager of my own business. The beauty salon is very successful. Summer, winter, no matter what time of the year it is, we are successful.`}</p>
    <p>{`I bought an apartment in Manhattan and decided to live a single life with my cat. I have reached this with the help of my friends and risk-taking. I would probably still be stuck in the company and getting headaches from the amount of work if I hadn’t quit to find my own path.`}</p>
    <p>{`All I can say is that it’s worth taking risks in life and that women can do anything and reach any goals they set themselves.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      